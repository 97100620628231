import { useContext } from 'react';
import { AuthContext } from '../../../App';
import { FormFields } from '../../../components';
import { fieldBuilder } from '../../../utils';
import { deliveryTimeOptions, zoneOptions, buyerTypeOptions } from './options';
import Map from "./Map";

type Props = {
  formik: any;
  handleLocation: Function;
  setValueAddress: Function;
  localityOptions: any[];
};

const CustomerInformationForm = ({ formik, handleLocation, setValueAddress, localityOptions }: Props) => {
  const { userState } = useContext(AuthContext);
  console.log(userState);
  const fields = [
    { id: 'firstname', label: 'Nombre' },
    { id: 'lastname', label: 'Apellido' },
    { id: 'dni', label: 'DNI', defaultValue: '' },
    { id: 'address', label: 'Dirección de envío' },
    { id: 'beetwenstreets', label: 'Entre calles' },
    { id: 'zone', label: 'Zona', type: 'select', options: zoneOptions },
    { id: 'locality', label: 'Localidad', type: 'select', options: localityOptions },
    { id: 'latitude', label: 'Latitud' },
    { id: 'longitude', label: 'Longitud' },
    { id: 'comments', label: 'Comentarios' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Teléfono' },
    {
      id: 'typeclient',
      label: 'Tipo de comprador',
      type: 'select',
      options: buyerTypeOptions.filter(x => userState.listPrice.find(y => y.listPrice===x.value)),
    },
    {
      id: 'deliverycost',
      label: 'Adicional por Localidad',
      type: 'number',
      hidden: formik.values.zone === 5,
    },
    {
      id: 'ventaFlete',
      label: 'Venta Flete Full',
      type: 'number'
    },
  ]
    .map((x) => fieldBuilder(x, formik))
    .filter((x) => !x.hidden);
  
  const { longitude, latitude } = formik.values;
  const location = { lat:latitude, lng:longitude };
  return (
    <form>
      <FormFields fields={fields} />
      <div style={{ height: '75vh', width: '100%' }}>
        <Map data={location} handleLocation={handleLocation} />
      </div>
    </form>
  );
};

export default CustomerInformationForm;
