import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('El nombre es requerido'),
  sku: yup.string().required('El SKU es requerida'),
  idCategory: yup.number().required('Debe seleccionar una categoría'),
  idSubCategory: yup.number().required('Debe seleccionar una sub-categoría'),
  retailPrice: yup.number().typeError('El precio debe ser un número'),
  fullPrice: yup.number().typeError('El precio debe ser un número'),
  stock: yup
    .number()
    .typeError('El stock debe ser un número')
    .required('El stock es requerido'),
  cost: yup.number().typeError('El costo debe ser un número'),
  collaboratorCommissionRetail: yup
    .number()
    .typeError('La comisión debe ser un número'),
  sellerCommissionRetail: yup
    .number()
    .typeError('La comisión debe ser un número'),
  collaboratorCommissionFull: yup
    .number()
    .typeError('La comisión debe ser un número'),
  sellerCommissionFull: yup
    .number()
    .typeError('La comisión debe ser un número'),
});
