export const encode_utf8 = (s) => {
    try {
        return encodeURIComponent(s);
    } catch {
        return s;
    }
}
  
export const decode_utf8 = (s) => {
    try {
        return decodeURIComponent(s)
        .replace(/Ã³/g, "ó")
        .replace(/Ã¡/g, "á")
        .replace(/Ã©/g, "é")
        .replace(/Ã­/g, "í")
        .replace(/Ãº/g, "ú")
        .replace(/Ã±/g, "ñ")
        .replace(/Ã€/g, "À")
        .replace(/Ã/g, "Á")
        .replace(/Ã‰/g, "É")
        .replace(/Ã/g, "Í")
        .replace(/Ã“/g, "Ó")
        .replace(/Ãš/g, "Ú")
        .replace(/Ã‘/g, "Ñ");
    } catch {
        return s;
    }
}