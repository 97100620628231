import * as yup from 'yup';
import { buyerTypeOptions, deliveryTimeOptions, zoneOptions } from './options';

export const validationSchema = yup.object({
  firstname: yup.string().required('El nombre es requerido'),
  lastname: yup.string().required('El apellido es requerido'),
  address: yup.string()
  .nullable(true)
  .when('zone', {
    is: (x: any) => x !== 5,
    then: yup.string().required('La dirección es requerida'),
  }),
  zone: yup
    .number()
    .test('test-zone', 'Zona inválida', (x) =>
      zoneOptions.map((y) => y.value).includes(x || -1)
    ),
  dni: yup.number().nullable(true).typeError('Dni invalido'),
  locality: yup.string()
  .nullable(true)
  .when('zone', {
    is: (x: any) => x !== 5,
    then: yup.string().required('La localidad es requerida'),
  }),
  latitude: yup.number().typeError('Latitud invalida'),
  longitude: yup.number().typeError('Longitud invalida'),
  email: yup.string().email('El formato del email es incorrecto'),
  phone: yup.string().required('El teléfono es requerido'),
  typeclient: yup
    .number()
    .required('El tipo de comprador es requerido')
    .test('test-buyerType', 'Tipo de comprador inválido', (x) =>
      buyerTypeOptions.map((y) => y.value).includes(x || -1)
    ),
    deliverycost: yup
    .number()
    .nullable(true)
    .when('zone', {
      is: (x: any) => x !== 5,
      then: yup.number().required('Debe ingresar un costo'),
    }),
});
