  import React, { useEffect, useState } from 'react';
  import { useParams, useHistory } from 'react-router-dom';
  import { sellsService } from '../../../services';
  import { Button } from '@material-ui/core';
  import './style.css';

  // Función de formateo de fecha
  const formatFecha = (fechaISO) => {
    if (!fechaISO) return ''; // Manejar caso de fecha nula o indefinida
    const fecha = new Date(fechaISO);
    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(fecha);
  };

  const decode_utf8 = (str: string) => {
    try {
      let decoded = new TextDecoder("utf-8").decode(new TextEncoder().encode(str));
  
      // Corrección de caracteres mal codificados
      decoded = decoded
        .replace(/Ã³/g, "ó")
        .replace(/Ã¡/g, "á")
        .replace(/Ã©/g, "é")
        .replace(/Ã­/g, "í")
        .replace(/Ãº/g, "ú")
        .replace(/Ã±/g, "ñ")
        .replace(/Ã€/g, "À")
        .replace(/Ã/g, "Á")
        .replace(/Ã‰/g, "É")
        .replace(/Ã/g, "Í")
        .replace(/Ã“/g, "Ó")
        .replace(/Ãš/g, "Ú")
        .replace(/Ã‘/g, "Ñ");
  
      return decoded;
    } catch (e) {
      return str;
    }
  };
  

  type Product = {
    id: string;
    name: string;
    sku: string;
    shippingUnitCost: number;
  };
  
  type ProductCar = {
    idProduct: string;
    quantity: number;
    price: number;
    cost: number;
    sellerComission: number;
    collaboratorComission: number;
    sku: string;
  };

  type Venta = {
    nroVenta: string;
    deliverydate: string;
    deliverycost: number;
    totalprice: number;
    totalcost: number;
    seller: {
      firstName: string;
      lastName: string;
    };
    client: {
      firstname: string;
      lastname: string;
      address: string;
      phone: string;
      locality: string;
      dni: string;
      beetwenstreets: string;
      comments: string;
      ventaFlete: number;
    };
    product: Product[];
    productCar: ProductCar[];
  };

  const calcularTotalCost = (productosCar: ProductCar[], productos: Product[], zone) => {
    if (!productosCar || productosCar.length === 0 || !productos || productos.length === 0) return 0;
  
    return productosCar.reduce((total, productoCar) => {
      const productInfo = productos.find(p => p.id === productoCar.idProduct);
  
      const quantity = Number(productoCar.quantity) || 0;
      const price = Number(productoCar.price) || 0;
      const shippingUnitCost = productInfo ? Number(productInfo.shippingUnitCost) || 0 : 0;
      
      if( zone === 5 ) return total + quantity * (price); 
      return total + quantity * (price + shippingUnitCost);
    }, 0);
  };  
  
  const RemitoPage = () => {
    const { nroVenta } = useParams<{ nroVenta?: string }>();
    const [venta, setVenta] = useState<Venta | null>(null);
    const history = useHistory();

    useEffect(() => {
      const fetchVenta = async () => {
        try {
          const ventaData = await sellsService.find(nroVenta);
          console.log('Venta:', ventaData);
    
          if (ventaData && Array.isArray(ventaData.productCar) && Array.isArray(ventaData.product)) {
            const totalCostCalculado = calcularTotalCost(ventaData.productCar, ventaData.product, ventaData.zone);
    
            setVenta({ ...ventaData, totalcost: totalCostCalculado });
          } else {
            setVenta(ventaData);
          }
        } catch (error) {
          console.error('Error fetching venta:', error);
        }
      };
    
      fetchVenta();
    }, [nroVenta]);    
    
    const handleCancel = () => {
      history.goBack();
    };
  
    const handlePrint = () => {
      window.print();
    };

    const renderProductos = () => {
    if (!venta || !venta.product || !venta.productCar) {
      return <p>No hay productos en esta venta.</p>;
    }

    return venta.productCar.map((productoCar) => {
      const productInfo = venta.product.find(p => p.id === productoCar.idProduct);

      return (
        <tr key={productoCar.idProduct}>
          <td>{productInfo ? productInfo.sku : ''}</td>
          <td>{productInfo ? productInfo.name : 'Producto desconocido'}</td>
          <td>{productoCar.quantity}</td>
          <td></td>
        </tr>
      );
    });
  };
  
  return (
    <div className="remito-container">
      <div className="remito-header">
        <div className="remito-vendedor">
          <img
            src={`https://quickchart.io/qr?text=https://tiendastic.app/api/logistic/${venta?.nroVenta}&size=150`}
            alt="QR"
          />
        </div>
        <div className="remito-info">
          <h2>{venta ? decode_utf8(venta.client.locality) : ''}</h2>
          <p><strong>Fecha:</strong> {venta ? formatFecha(venta.deliverydate) : ''}</p>
          <p><strong>N° Pedido:</strong> {venta ? venta.nroVenta : ''}</p>
          <p><strong>Vendedor:</strong> {venta ? `${venta.seller.firstName} ${venta.seller.lastName}` : ''}</p>
        </div>
      </div>
      <div className="remito-cliente">
        <p><strong>Nombre:</strong> {venta ? decode_utf8(`${venta.client.firstname} ${venta.client.lastname}`) : ''}<span className="remito-telefono"><strong>DNI:</strong> {venta ? venta.client.dni : ''}</span></p>
        <p><strong>Teléfono:</strong> {venta ? venta.client.phone : ''}</p>
        <p><strong>Dirección:</strong> {venta ? decode_utf8(venta.client.address) : ''}</p>   
        <p><strong>Entre Calles:</strong> {venta ? decode_utf8(venta.client.beetwenstreets) : ''}</p>        
      </div>
      <table className="remito-productos">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Artículo</th>
            <th>Cant</th>
            <th>Precio total</th>
          </tr>
        </thead>
        <tbody>
          {renderProductos()}
        </tbody>
      </table>
      <div className="remito-comentarios">
          <p><strong>{venta ? decode_utf8(venta.client.comments) : ''}</strong></p> 
        </div>
      <div className="remito-right">
        <div className="remito-total">
          <p><strong>Total:</strong> $ {venta ? venta.totalcost + venta.deliverycost + venta.client.ventaFlete : 0}</p> 
        </div>
      </div>
      <div className="remito-duplicado">
        <div className="remito-header">
          <div className="remito-vendedor">
            <img
              src={`https://quickchart.io/qr?text=https://tiendastic.app/api/logistic/${venta?.nroVenta}&size=120`}
              alt="QR"
            />
          </div>
          <div className="remito-info">
            <h2>{venta ? decode_utf8(venta.client.locality) : ''}</h2>
            <p><strong>Fecha:</strong> {venta ? formatFecha(venta.deliverydate) : ''}</p>
            <p><strong>N° Pedido:</strong> {venta ? venta.nroVenta : ''}</p>
            <p><strong>Vendedor:</strong> {venta ? `${venta.seller.firstName} ${venta.seller.lastName}` : ''}</p>
          </div>
        </div>
        <div className="remito-cliente">
          <p><strong>Nombre:</strong> {venta ? decode_utf8(`${venta.client.firstname} ${venta.client.lastname}`) : ''}<span className="remito-telefono"><strong>DNI:</strong> {venta ? venta.client.dni : ''}</span></p>
          <p><strong>Teléfono:</strong> {venta ? venta.client.phone : ''}</p>
          <p><strong>Dirección:</strong> {venta ? decode_utf8(venta.client.address) : ''}</p>   
          <p><strong>Entre Calles:</strong> {venta ? decode_utf8(venta.client.beetwenstreets) : ''}</p>        
        </div>
        <table className="remito-productos">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Artículo</th>
              <th>Cant</th>
              <th>Precio total</th>
            </tr>
          </thead>
          <tbody>
            {renderProductos()}
          </tbody>
        </table>
        <div className="remito-right">
          <div className="remito-total">
            <p><strong>Total:</strong> $ {venta ? venta.totalcost + venta.deliverycost + venta.client.ventaFlete : 0}</p> 
          </div>
        </div>
      </div>
      <div className="remito-footer">
        <Button variant="contained" color="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Imprimir
        </Button>
      </div>
    </div>
  );
};
  
  export default RemitoPage;
  