import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicator, FormActions, FormFields } from '../../../components';
import { fieldBuilder } from '../../../utils';
import { validationSchema } from './validationSchema';
import { useCRUD, useNotifications } from '../../../hooks';
import { categoriesService, productsService } from '../../../services';

const categoryOptionsMapper = (categories: any) => {
  return categories.map((x: any) => ({ label: x.name, value: x.id }));
};

const subCategoryOptionsMapper = (categories: any, selectedCategory: any) => {
  const category = categories.find((x: any) => x.id === selectedCategory);
  if (!category) return [];
  return category.subcategories.map((x: any) => ({
    label: x.name,
    value: x.id,
  }));
};

const numberFieldProps = {
  type: 'number',
  InputProps: { inputProps: { min: 0 } },
};

const defaultValues: { [key: string]: any } = {
  retailPrice: 0,
  fullPrice: 0,
  fullPrice2: 0,
  // fullPrice3: 0,
  stock: 0,
  cost: 0,
  collaboratorCommissionRetail: 0,
  sellerCommissionRetail: 0,
  collaboratorCommissionFull: 0,
  sellerCommissionFull: 0,
};

const formatValues = (values: any) => {
  const newValues: { [key: string]: any } = {};

  for (var key in values) {
    newValues[key] = values[key] || defaultValues[key];
  }

  return newValues;
};

const ProductsForm = ({ mode }: { mode: string }) => {
  const title = `${mode === 'add' ? 'Agregar' : 'Editar'} producto`;
  const history = useHistory();
  const { state: locationState }: any = useLocation();
  const { showNotification } = useNotifications();

  const {
    handleAdd,
    handleEdit,
    loading,
  } = useCRUD(
    null,
    productsService.add,
    productsService.edit,
    productsService.remove,
    showNotification,
    productsService.getMessages
  );

  const { loading: loadingCategories, state: categoriesAndSubcategories } =
    useCRUD(
      categoriesService.getCategoriesAndSubCategories,
      async () => {},
      async () => {},
      async () => {},
      showNotification,
      categoriesService.getMessages
    );

  const defaultInitial = {
    sku: locationState?.sku || '',
    ean: locationState?.ean || '',
    name: locationState?.name || '',
    description: locationState?.description || '',
    idCategory: locationState?.idCategory || null,
    idSubCategory: locationState?.idSubCategory || null,
    retailPrice: locationState?.retailPrice || '',
    collaboratorCommissionRetail: locationState?.collaboratorCommissionRetail || '',
    sellerCommissionRetail: locationState?.sellerCommissionRetail || '',
    fullPrice: locationState?.fullPrice || '',
    collaboratorCommissionFull: locationState?.collaboratorCommissionFull || '',
    sellerCommissionFull: locationState?.sellerCommissionFull || '',
    fullPrice2: locationState?.fullPrice2 || '',
    collaboratorCommissionFull2: locationState?.collaboratorCommissionFull2 || '',
    sellerCommissionFull2: locationState?.sellerCommissionFull2 || '',
    // fullPrice3: locationState?.fullPrice3 || '',
    // collaboratorCommissionFull3: locationState?.collaboratorCommissionFull3 || '',
    // sellerCommissionFull3: locationState?.sellerCommissionFull3 || '',
    gridPrice: locationState?.gridPrice || '',
    collaboratorCommissionGrid: locationState?.collaboratorCommissionGrid || '',
    sellerCommissionGrid: locationState?.sellerCommissionGrid || '',
    stock: locationState?.stock || '',
    gridStock: locationState?.gridStock || '',
    shippingUnitCost: locationState?.shippingUnitCost || '',
    cost: locationState?.cost || '',
  };

  const formik = useFormik({
    initialValues: defaultInitial,
    validationSchema: validationSchema,
    onSubmit: async (rawValues) => {
      const values = formatValues(rawValues);
      if (mode === 'add') await handleAdd(values);
      else await handleEdit({ ...values, id: locationState.id });
      history.goBack();
    },
  });

  const fields = [
    { id: 'sku', label: 'SKU' },
    { id: 'ean', label: 'EAN' },
    { id: 'name', label: 'Nombre' },
    { id: 'description', label: 'Descripción' },
    {
      id: 'idCategory',
      label: 'Categoría',
      type: 'select',
      options: categoryOptionsMapper(categoriesAndSubcategories),
    },
    {
      id: 'idSubCategory',
      label: 'Sub-categoría',
      type: 'select',
      options: subCategoryOptionsMapper(
        categoriesAndSubcategories,
        formik.values.idCategory
      ),
    },
    {
      id: 'cost',
      label: 'Costo',
      ...numberFieldProps,
    },
    {
      id: 'shippingUnitCost',
      label: 'Costo unitario de envío',
      ...numberFieldProps,
    },
    { id: 'stock', label: 'Stock', ...numberFieldProps },
    { id: 'retailPrice', label: 'Precio (Min.)', ...numberFieldProps },
    {
      id: 'collaboratorCommissionRetail',
      label: 'Comisión de colaborador (Venta minorista)',
      ...numberFieldProps,
    },
    {
      id: 'sellerCommissionRetail',
      label: 'Comisión de vendedor Venta minorista)',
      ...numberFieldProps,
    },
    { id: 'fullPrice', label: 'Precio (May.)', ...numberFieldProps },
    {
      id: 'collaboratorCommissionFull',
      label: 'Comisión de colaborador (Venta mayorista)',
      ...numberFieldProps,
    },
    {
      id: 'sellerCommissionFull',
      label: 'Comisión de vendedor (Venta mayorista)',
      ...numberFieldProps,
    },
    { id: 'fullPrice2', label: 'Precio (May. 2)', ...numberFieldProps },
    {
      id: 'collaboratorCommissionFull2',
      label: 'Comisión de colaborador (Venta mayorista 2)',
      ...numberFieldProps,
    },
    {
      id: 'sellerCommissionFull2',
      label: 'Comisión de vendedor (Venta mayorista 2)',
      ...numberFieldProps,
    },
    // { id: 'fullPrice3', label: 'Precio (May. 3)', ...numberFieldProps },
    // {
    //   id: 'collaboratorCommissionFull3',
    //   label: 'Comisión de colaborador (Venta mayorista 3)',
    //   ...numberFieldProps,
    // },
    // {
    //   id: 'sellerCommissionFull3',
    //   label: 'Comisión de vendedor (Venta mayorista 3)',
    //   ...numberFieldProps,
    // },
    { id: 'gridPrice', label: 'Precio (Redes)', ...numberFieldProps },
    {
      id: 'collaboratorCommissionGrid',
      label: 'Comisión de colaborador (Venta Redes)',
      ...numberFieldProps,
    },
    {
      id: 'sellerCommissionGrid',
      label: 'Comisión de vendedor (Venta Redes)',
      ...numberFieldProps,
    },
    { id: 'gridStock', label: 'Stock (Redes)', ...numberFieldProps },
  ]
    .map((x) => fieldBuilder(x, formik))
    .filter((x) => !x.hidden);

  return (
    <>
      <LoadingIndicator loading={loading || loadingCategories} />

      <h1>{title}</h1>

      <form onSubmit={formik.handleSubmit}>
        <FormFields fields={fields} />
        <FormActions baseUrl="/productos" onCancel={history.goBack} />
      </form>
    </>
  );
};

export default ProductsForm;
