import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicator, FormActions } from '../../../components';
import FileUpload from '../../../components/FileUpload'; 
import { validationSchema } from '../form/validationSchema';
import { useCRUD, useNotifications } from '../../../hooks';
import { categoriesService, productsService } from '../../../services';
import { parseCSVFile } from '../../../utils/csvParser';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const ProductsUpdate = ({ mode }: { mode: string }) => {
  const title = 'Actualización masiva';
  const history = useHistory();
  const { state: locationState }: any = useLocation();
  const { showNotification } = useNotifications();

  const [fileData, setFileData] = useState<any[]>([]);
  const [previewData, setPreviewData] = useState<any[]>([]);

  const {
    handleAdd,
    handleEdit,
    loading,
  } = useCRUD(
    null,
    productsService.add,
    productsService.edit,
    productsService.remove,
    showNotification,
    productsService.getMessages
  );

  const { loading: loadingCategories, state: categoriesAndSubcategories } =
    useCRUD(
      categoriesService.getCategoriesAndSubCategories,
      async () => {},
      async () => {},
      async () => {},
      showNotification,
      categoriesService.getMessages
    );

    const formik = useFormik({
      initialValues: {},
      onSubmit: async () => {
        try {
          const failedUpdates: number[] = [];
          for (const product of previewData) {
            const productDTO = mapCSVToDTO(product);
            try {
              await handleEdit(productDTO); // Actualizar producto
            } catch (error) {
              console.error(`Error al actualizar el producto con ID ${product.ID}:`, error);
              failedUpdates.push(product.ID);
            }
          }
  
          if (failedUpdates.length > 0) {
            showNotification(
              'error',
              `No se pudieron actualizar los productos con ID: ${failedUpdates.join(', ')}`
            );
          } else {
            showNotification('success', 'Cambios aplicados correctamente');
          }
          history.goBack();
        } catch (error) {
          console.error('Error general al aplicar los cambios:', error);
          showNotification('error', 'Error al aplicar los cambios');
        }
      },
    });

  // Procesar el archivo CSV
  const handleFileUpload = async (file: File) => {
    try {
      const data = await parseCSVFile(file);
      // Filtrar filas válidas (requieren un ID)
      const validData = data.filter((row) => row.ID && !isNaN(parseInt(row.ID, 10)));
      console.log('Datos válidos cargados:', validData);
      setPreviewData(validData);
    } catch (error) {
      console.error('Error al cargar los datos:', error);
      showNotification('error', 'Error al cargar el archivo CSV');
    }
  };

  // Mapear los datos del CSV a la estructura esperada del DTO
  const mapCSVToDTO = (product) => {
    return {
      Id: parseInt(product.ID, 10),
      ...(product.SKU && { Sku: product.SKU }),
      ...(product.EAN && { Ean: product.EAN }),
      ...(product.NOMBRE && { Name: product.NOMBRE }),
      ...(product['DESCRIPCIÓN'] && { Category: product['DESCRIPCIÓN'] }), 
      ...(product['CATEGORÍA'] && { Category: product['CATEGORÍA'] }),
      ...(product['SUB-CATEGORÍA'] && { SubCategory: product['SUB-CATEGORÍA'] }),
      ...(product['PRECIO (MIN.)'] && { RetailPrice: parseFloat(product['PRECIO (MIN.)']) }),
      ...(product['COMISIÓN DE COLABORADOR (VENT. MIN.)'] && {
        CollaboratorCommissionRetail: parseFloat(product['COMISIÓN DE COLABORADOR (VENT. MIN.)']),
      }),
      ...(product['COMISIÓN DE VENDEDOR (VENT. MIN.)'] && {
        SellerCommissionRetail: parseFloat(product['COMISIÓN DE VENDEDOR (VENT. MIN.)']),
      }),
      ...(product['PRECIO (MAY.)'] && { FullPrice: parseFloat(product['PRECIO (MAY.)']) }),
      ...(product['COMISIÓN DE COLABORADOR (VENT. MAY.)'] && {
        CollaboratorCommissionFull: parseFloat(product['COMISIÓN DE COLABORADOR (VENT. MAY.)']),
      }),
      ...(product['COMISIÓN DE VENDEDOR (VENT. MAY.)'] && {
        SellerCommissionFull: parseFloat(product['COMISIÓN DE VENDEDOR (VENT. MAY.)']),
      }),
      ...(product['PRECIO (MAY. 2)'] && { FullPrice2: parseFloat(product['PRECIO (MAY. 2)']) }),
      ...(product['COMISIÓN DE COLABORADOR (VENT. MAY. 2)'] && {
        CollaboratorCommissionFull2: parseFloat(product['COMISIÓN DE COLABORADOR (VENT. MAY. 2)']),
      }),
      ...(product['COMISIÓN DE VENDEDOR (VENT. MAY. 2)'] && {
        SellerCommissionFull2: parseFloat(product['COMISIÓN DE VENDEDOR (VENT. MAY. 2)']),
      }),
      ...(product.STOCK && { stock: parseInt(product.STOCK, 10) }),
      ...(product['PRECIO REDES'] && { gridPrice: parseFloat(product['PRECIO REDES']) }),
      ...(product['COMISIÓN DE COLABORADOR (REDES)'] && {
        CollaboratorCommissionGrid: parseFloat(product['COMISIÓN DE COLABORADOR (REDES)']),
      }),
      ...(product['COMISIÓN DE VENDEDOR (REDES)'] && {
        SellerCommissionGrid: parseFloat(product['COMISIÓN DE VENDEDOR (REDES)']),
      }),
      ...(product['STOCK REDES'] && { gridStock: parseInt(product['STOCK REDES'], 10) }),
      ...(product['COSTO UNIT. ENVÍO'] && {
        ShippingUnitCost: parseFloat(product['COSTO UNIT. ENVÍO']),
      }),
      ...(product.COSTO && { Cost: parseFloat(product.COSTO) }),
    };
  };

  return (
    <>
      <LoadingIndicator loading={loading || loadingCategories} />

      <h1>{title}</h1>

      <form onSubmit={formik.handleSubmit}>
        <FileUpload onFileUpload={handleFileUpload} />

        {previewData.length > 0 && (
          <>
            <h2>Vista previa de los cambios</h2>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(previewData[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, i) => (
                      <TableCell key={i}>{String(value)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        <FormActions baseUrl="/productos" />
        <Button type="submit" color="primary" variant="contained" disabled={loading || loadingCategories}>
          Aplicar cambios
        </Button>
      </form>
    </>
  );
};

export default ProductsUpdate;
