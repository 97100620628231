import { GridCellParams } from '@material-ui/data-grid'
import { GridActions } from '../../../components'
import { RowBody } from '../../../components/grid/CustomGrid/types';

type Params = {
	onEdit: (x: GridCellParams) => void,
	onDelete: (x: GridCellParams) => void,
	rows: any
}


export function GetDataBody({ rows,
	onEdit,
	onDelete
}: Params) {
	let dataBody: RowBody[] = [];

	const getAccions = (row: any) => {

		return (
			<>
				<GridActions
					params={{ row }}
					handleEdit={onEdit}
					handleDelete={onDelete}
				/>
			</>
		);
	}


	function GetRow(row: any): any[] {
		return [
			{
				cells: [
					{ key: 'id', field: row.id },
					{ key: 'sku', field: row.sku },
					{ key: 'ean', field: row.ean },
					{ key: 'name', field: row.name, tableCellProps: { component: "th", scope: "row", className: "stickySaeta" } },
					{ key: 'description', field: row.description },
					{ key: 'idCategory', field: row.category && row.category.name },
					{ key: 'idSubCategory', field: row.subCategory && row.subCategory.name },
					{ key: 'retailPrice', field: row.retailPrice },
					{ key: 'collaboratorCommissionRetail', field: row.collaboratorCommissionRetail },
					{ key: 'sellerCommissionRetail', field: row.sellerCommissionRetail },
					{ key: 'fullPrice', field: row.fullPrice },
					{ key: 'collaboratorCommissionFull', field: row.collaboratorCommissionFull },
					{ key: 'sellerCommissionFull', field: row.sellerCommissionFull },
					{ key: 'fullPrice2', field: row.fullPrice2 },
					{ key: 'collaboratorCommissionFull2', field: row.collaboratorCommissionFull2 },
					{ key: 'sellerCommissionFull2', field: row.sellerCommissionFull2 },
					// { key: 'fullPrice3', field: row.fullPrice3 },
					// { key: 'collaboratorCommissionFull3', field: row.collaboratorCommissionFull3 },
					// { key: 'sellerCommissionFull3', field: row.sellerCommissionFull3 },
					{ key: 'stock', field: row.stock },
					{ key: 'gridPrice', field: row.gridPrice },
					{ key: 'collaboratorCommissionGrid', field: row.collaboratorCommissionGrid },
					{ key: 'sellerCommissionGrid', field: row.sellerCommissionGrid },
					{ key: 'gridStock', field: row.gridStock },
					{ key: 'shippingUnitCost', field: row.shippingUnitCost },
					{ key: 'cost', field: row.cost },
					{ key: 'actions', field: getAccions(row) }
				],
				tableRowProps: {
					hover: true
				}
			}
		]
	}

	rows.map((row: any) => {
		row.id && GetRow(row).map((data: any) => dataBody.push(data));
	});
	return dataBody;
}

export const getDataHead = () => {
	let dataHeadTag = sessionStorage.getItem('dataHeadProducts');
	if (dataHeadTag) {
		return JSON.parse(dataHeadTag)
	}
	return dataHead();
}

export const dataHead = () => [
	{ key: 'id', field: "ID", tableCellProps: { align: "left", width: 10, className: "separador" } },
	{ key: 'sku', field: "SKU", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'ean', field: "EAN", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'name', field: "Nombre", tableCellProps: { align: "center", width: 40, className: "separador stickySaeta" } },
	{ key: 'description', field: "Descripción", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'idCategory', field: "Categoría", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'idSubCategory', field: "Sub-categoría", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'retailPrice', field: "Precio (Min.)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionRetail', field: "Com Col Min.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionRetail', field: "Com Vend Min.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'fullPrice', field: "Precio (May.)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionFull', field: "Com Col Max.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionFull', field: "Com Vend Máx.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'fullPrice2', field: "Precio (May. 2)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionFull2', field: "Com Col Max. 2", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionFull2', field: "Com Vend Máx. 2", tableCellProps: { align: "center", width: 250, className: "separador" } },
	// { key: 'fullPrice3', field: "Precio (May. 3)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	// { key: 'collaboratorCommissionFull3', field: "Com Col Max. 3", tableCellProps: { align: "center", width: 250, className: "separador" } },
	// { key: 'sellerCommissionFull3', field: "Com Vend Máx. 3", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'stock', field: "Stock", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'gridPrice', field: "Precio (Redes)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionGrid', field: "Com Col Redes", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionGrid', field: "Com Vend Redes", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'gridStock', field: "Stock Redes", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'shippingUnitCost', field: "Costo Unit. Envío", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'cost', field: "Costo", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'actions', field: "Acciones", tableCellProps: { align: "center" } }
];

export const headerExport: any[] = [
	{ key: 'id', label: 'ID' },
	{ key: 'sku', label: 'SKU' },
	{ key: 'ean', label: 'EAN' },
	{ key: 'name', label: 'Nombre' },
	{ key: 'description', label: 'SKU' },
	{ key: 'idCategory', label: 'Categoría' },
	{ key: 'idSubCategory', label: 'Sub-categoría' },
	{ key: 'retailPrice', label: 'Precio (Min.)' },
	{ key: 'collaboratorCommissionRetail', label: 'Comisión de colaborador (Vent. Min.)' },
	{ key: 'sellerCommissionRetail', label: 'Comisión de vendedor (Vent. Min.)' },
	{ key: 'fullPrice', label: 'Precio (May.)' },
	{ key: 'collaboratorCommissionFull', label: 'Comisión de colaborador (Vent. May.)' },
	{ key: 'sellerCommissionFull', label: 'Comisión de vendedor (Vent. May.)' },
	{ key: 'fullPrice2', label: 'Precio (May. 2)' },
	{ key: 'collaboratorCommissionFull2', label: 'Comisión de colaborador (Vent. May. 2)' },
	{ key: 'sellerCommissionFull2', label: 'Comisión de vendedor (Vent. May. 2)' },
	// { key: 'fullPrice3', label: 'Precio (May. 3)' },
	// { key: 'collaboratorCommissionFull3', label: 'Comisión de colaborador (Vent. May. 3)' },
	// { key: 'sellerCommissionFull3', label: 'Comisión de vendedor (Vent. May. 3)' },
	{ key: 'stock', label: 'Stock' },
	{ key: 'gridPrice', label: 'Precio Redes' },
	{ key: 'collaboratorCommissionGrid', label: 'Comisión de colaborador (Redes)' },
	{ key: 'sellerCommissionGrid', label: 'Comisión de vendedor (Redes)' },
	{ key: 'gridStock', label: 'Stock Redes' },
	{ key: 'shippingUnitCost', label: 'Costo Unit. Envío' },
	{ key: 'cost', label: 'Costo' },
];

export function getDataExport(rows: any[]) {
	let dataBody: any[] = [];

	rows.map((row: any) => (
		dataBody.push(
			{
				id: row.id,
				sku: row.sku,
				ean: row.ean,
				name: row.name,
				description: row.description,
				idCategory: row.category && row.category.name,
				idSubCategory: row.subCategory && row.subCategory.name,
				retailPrice: row.retailPrice,
				collaboratorCommissionRetail: row.collaboratorCommissionRetail,
				sellerCommissionRetail: row.sellerCommissionRetail,
				fullPrice: row.fullPrice,
				collaboratorCommissionFull: row.collaboratorCommissionFull,
				sellerCommissionFull: row.sellerCommissionFull,
				fullPrice2: row.fullPrice2,
				collaboratorCommissionFull2: row.collaboratorCommissionFull2,
				sellerCommissionFull2: row.sellerCommissionFull2,
				// fullPrice3: row.fullPrice3,
				// collaboratorCommissionFull3: row.collaboratorCommissionFull3,
				// sellerCommissionFull3: row.sellerCommissionFull3,
				stock: row.stock,
				gridPrice: row.gridPrice,
				collaboratorCommissionGrid: row.collaboratorCommissionGrid,
				sellerCommissionGrid: row.sellerCommissionGrid,
				gridStock: row.gridStock,
				shippingUnitCost: row.shippingUnitCost,
				cost: row.cost
			}))
	);
	return dataBody;
}

export function GetDataBody2({ rows,
	onEdit,
	onDelete
}: Params) {
	let dataBody: RowBody[] = [];

	const getAccions = (row: any) => {

		return (
			<>
				<GridActions
					params={{ row }}
					handleEdit={onEdit}
					handleDelete={onDelete}
				/>
			</>
		);
	}


	function GetRow(row: any): any[] {
		return [
			{
				cells: [
					{ key: 'id', field: row.id },
					{ key: 'sku', field: row.sku },
					{ key: 'ean', field: row.ean },
					{ key: 'name', field: row.name, tableCellProps: { component: "th", scope: "row", className: "stickySaeta" } },
					{ key: 'description', field: row.description },
					{ key: 'idCategory', field: row.category && row.category.name },
					{ key: 'idSubCategory', field: row.subCategory && row.subCategory.name },
					{ key: 'retailPrice', field: row.retailPrice },
					{ key: 'collaboratorCommissionRetail', field: row.collaboratorCommissionRetail },
					{ key: 'sellerCommissionRetail', field: row.sellerCommissionRetail },
					{ key: 'fullPrice', field: row.fullPrice },
					{ key: 'collaboratorCommissionFull', field: row.collaboratorCommissionFull },
					{ key: 'sellerCommissionFull', field: row.sellerCommissionFull },
					{ key: 'fullPrice2', field: row.fullPrice2 },
					{ key: 'collaboratorCommissionFull2', field: row.collaboratorCommissionFull2 },
					{ key: 'sellerCommissionFull2', field: row.sellerCommissionFull2 },
					// { key: 'fullPrice3', field: row.fullPrice3 },
					// { key: 'collaboratorCommissionFull3', field: row.collaboratorCommissionFull3 },
					// { key: 'sellerCommissionFull3', field: row.sellerCommissionFull3 },
					{ key: 'gridPrice', field: row.gridPrice },
					{ key: 'collaboratorCommissionGrid', field: row.collaboratorCommissionGrid },
					{ key: 'sellerCommissionGrid', field: row.sellerCommissionGrid },
					{ key: 'actions', field: getAccions(row) }
				],
				tableRowProps: {
					hover: true
				}
			}
		]
	}

	rows.map((row: any) => {
		row.id && GetRow(row).map((data: any) => dataBody.push(data));
	});
	return dataBody;
}

export const getDataHead2 = () => {
	let dataHeadTag = sessionStorage.getItem('dataHeadProducts');
	if (dataHeadTag) {
		return JSON.parse(dataHeadTag)
	}
	return dataHead2();
}

export const dataHead2 = () => [
	{ key: 'id', field: "ID", tableCellProps: { align: "center", width: 10, className: "separador" } },
	{ key: 'sku', field: "SKU", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'ean', field: "EAN", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'name', field: "Nombre", tableCellProps: { align: "center", width: 40, className: "separador stickySaeta" } },
	{ key: 'description', field: "Descripción", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'idCategory', field: "Categoría", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'idSubCategory', field: "Sub-categoría", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'retailPrice', field: "Precio (Min.)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionRetail', field: "Com Col Min.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionRetail', field: "Com Vend Min.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'fullPrice', field: "Precio (May.)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionFull', field: "Com Col Max.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionFull', field: "Com Vend Máx.", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'fullPrice2', field: "Precio (May. 2)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionFull2', field: "Com Col Max. 2", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionFull2', field: "Com Vend Máx. 2", tableCellProps: { align: "center", width: 250, className: "separador" } },
	// { key: 'fullPrice3', field: "Precio (May. 3)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	// { key: 'collaboratorCommissionFull3', field: "Com Col Max. 3", tableCellProps: { align: "center", width: 250, className: "separador" } },
	// { key: 'sellerCommissionFull3', field: "Com Vend Máx. 3", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'gridPrice', field: "Precio (Redes)", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'collaboratorCommissionGrid', field: "Com Col Redes", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'sellerCommissionGrid', field: "Com Vend Redes", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'actions', field: "Acciones", tableCellProps: { align: "center" } }
];

export const headerExport2: any[] = [
	{ key: 'id', label: 'ID' },
	{ key: 'sku', label: 'SKU' },
	{ key: 'ean', label: 'EAN' },
	{ key: 'name', label: 'Nombre' },
	{ key: 'description', label: 'Descripción' },
	{ key: 'idCategory', label: 'Categoría' },
	{ key: 'idSubCategory', label: 'Sub-categoría' },
	{ key: 'retailPrice', label: 'Precio (Min.)' },
	{ key: 'collaboratorCommissionRetail', label: 'Comisión de colaborador (Vent. Min.)' },
	{ key: 'sellerCommissionRetail', label: 'Comisión de vendedor (Vent. Min.)' },
	{ key: 'fullPrice', label: 'Precio (May.)' },
	{ key: 'collaboratorCommissionFull', label: 'Comisión de colaborador (Vent. May.)' },
	{ key: 'sellerCommissionFull', label: 'Comisión de vendedor (Vent. May.)' },
	{ key: 'fullPrice2', label: 'Precio (May. 2)' },
	{ key: 'collaboratorCommissionFull2', label: 'Comisión de colaborador (Vent. May. 2)' },
	{ key: 'sellerCommissionFull2', label: 'Comisión de vendedor (Vent. May. 2)' },
	// { key: 'fullPrice3', label: 'Precio (May. 3)' },
	// { key: 'collaboratorCommissionFull3', label: 'Comisión de colaborador (Vent. May. 3)' },
	// { key: 'sellerCommissionFull3', label: 'Comisión de vendedor (Vent. May. 3)' },
	{ key: 'gridPrice', label: 'Precio (Redes)' },
	{ key: 'collaboratorCommissionGrid', label: 'Comisión de colaborador (Vent. Redes)' },
	{ key: 'sellerCommissionGrid', label: 'Comisión de vendedor (Vent. Redes)' },
];

export function getDataExport2(rows: any[]) {
	let dataBody: any[] = [];

	rows.map((row: any) => (
		dataBody.push(
			{
				id: row.id,
				sku: row.sku,
				ean: row.ean,
				name: row.name,
				description: row.description,
				idCategory: row.category && row.category.name,
				idSubCategory: row.subCategory && row.subCategory.name,
				retailPrice: row.retailPrice,
				collaboratorCommissionRetail: row.collaboratorCommissionRetail,
				sellerCommissionRetail: row.sellerCommissionRetail,
				fullPrice: row.fullPrice,
				collaboratorCommissionFull: row.collaboratorCommissionFull,
				sellerCommissionFull: row.sellerCommissionFull,
				fullPrice2: row.fullPrice2,
				collaboratorCommissionFull2: row.collaboratorCommissionFull2,
				sellerCommissionFull2: row.sellerCommissionFull2,
				// fullPrice3: row.fullPrice3,	
				// collaboratorCommissionFull3: row.collaboratorCommissionFull3,
				// sellerCommissionFull3: row.sellerCommissionFull3,
				gridPrice: row.gridPrice,	
				collaboratorCommissionGrid: row.collaboratorCommissionGrid,
				sellerCommissionGrid: row.sellerCommissionGrid
			}))
	);
	return dataBody;
}